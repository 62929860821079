import React, { FunctionComponent } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image"

import { rhythm } from '../utils/typography';

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpeg/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`;

const Bio: FunctionComponent = () => {
  const data = useStaticQuery(bioQuery);
  const { author, social } = data.site.siteMetadata;
  return (
    <div style={{ display: 'flex' }}>
      <GatsbyImage
        image={data.avatar.childImageSharp.gatsbyImageData}
        alt={author}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          maxWidth: 64,
          maxHeight: 64,
          borderRadius: '100%',
        }}
        imgStyle={{
          borderRadius: '50%',
        }}
      />
      <p>
        Retrouvez-moi sur Twitter :
        <br />
        <a href={`https://twitter.com/${social.twitter}`} title={social.twitter}>{`@${social.twitter}`}</a>
      </p>
    </div>
  );
};

export default Bio;
