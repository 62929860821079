import React, { FunctionComponent } from 'react';

import { graphql, Link } from 'gatsby';

import Bio from '../components/Bio';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import TagLinkList from '../components/TagLinkList';
import { rhythm } from '../utils/typography';

export type BlogPostTemplateProps = {
  data: {
    site: {
      siteMetadata: {
        title: string;
        author: string;
        siteUrl: string;
      };
    };
    markdownRemark: {
      id: string;
      excerpt: string;
      html: string;
      fields: {
        slug: string;
      };
      frontmatter: {
        title: string;
        date: string;
        timeEmoji: string;
        emojiRatio: number;
        ogimage?: {
          childImageSharp: {
            gatsbyImageData: {
              images: {
                fallback : {
                  src: string;
                }
              }
            }
          }
        };
      };
    };
  };
  pageContext: {
    previous: any;
    next: any;
    tags: string[];
  };
  location: Location;
};

const BlogPostTemplate: FunctionComponent<BlogPostTemplateProps> = ({ data, pageContext, location }) => {
  const post = data.markdownRemark;
  const { title: siteTitle } = data.site.siteMetadata;
  const { previous, next, tags } = pageContext;
  const { ogimage, title } = post.frontmatter;
  const ogImagePath = ogimage && ogimage.childImageSharp.gatsbyImageData.images.fallback.src;

  return (
    <Layout location={location} title={siteTitle}>
    <Seo title={title} description={post.excerpt} ogImage={ogImagePath} />

      <h1
        style={{
          margin: `0 auto ${rhythm(1.25)} auto`,
          fontWeight: 'bold',
          fontSize: '2em',
          textAlign: 'center',
        }}
      >
        {title}
      </h1>

      <div style={{ textAlign: 'justify', maxWidth: 960, margin: '0 auto' }}>
        <div dangerouslySetInnerHTML={{ __html: post.html }}/>
        <div style={{ textAlign: 'right', marginBottom: rhythm(0.75), fontSize: '0.8em', color: '#777' }}>Publié le {post.frontmatter.date}</div>
        {tags && <TagLinkList tags={tags} />}
      </div>

      <hr style={{ marginBottom: rhythm(1), marginTop: rhythm(1) }} />
      <div style={{ maxWidth: 800, margin: '0 auto' }}>
        <Bio />
        {(previous || next) && (
          <ul
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              listStyle: 'none',
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev" title={previous.frontmatter.title}>
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next" title={next.frontmatter.title}>
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        )}
      </div>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 230)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD/MM/YYYY")
        timeEmoji
        emojiRatio
        ogimage {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, JPG]
            )
          }
        }
      }
    }
  }
`;
